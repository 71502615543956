import React from 'react'
import { Link } from 'gatsby'
import Menu from '../Menu'
import Links from '../Links'
import profilePic from '../../pages/photo.jpg'
import './style.scss'

class TopNavBar extends React.Component {
  render() {
    const { author, menu } = this.props.data.site.siteMetadata

    /* eslint-disable jsx-a11y/img-redundant-alt */
    const authorBlock = (
      <div className="authorBlock">
        <Link to="/">
          <img src={profilePic} className="navbar__author-photo" alt={author.name} />
        </Link>
      </div>
    )
    /* eslint-enable jsx-a11y/img-redundant-alt */

    return (
      <div className="navbar">
        <div className="navbar__author">{authorBlock}</div>
        <Menu data={menu} />
        <Links data={author} />
      </div>
    )
  }
}

export default TopNavBar
